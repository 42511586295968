import React, { useEffect, useState } from "react";
import { Container, Nav, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../../components/ImageUpload";
import ImageGallery from "../../components/ImageGallery";

interface DashboardProps {
  userName: string;
  onLogout: () => void;
}

const Dashboard: React.FC<DashboardProps> = ({ userName, onLogout }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState("Home");
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    console.log("Token on Dashboard:", token); // Verify token retrieval
    if (!token) {
      navigate("/login"); // Redirect to login if no token
    }
  }, [navigate]);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${apiUrl}/logout.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.success) {
        localStorage.removeItem("authToken");
        navigate("/login");
      } else {
        console.error("Logout failed: ", data.message);
      }
    } catch (err) {
      console.error("An error occurred while logging out", err);
    }
  };

  return (
    <div className="dashboard-container">
      <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
        <Button
          className="toggle-btn"
          onClick={() => setIsCollapsed(!isCollapsed)}
          variant="warning"
          style={{ margin: "10px" }}
        >
          {isCollapsed ? (
            <i className="bi bi-list"></i>
          ) : (
            <i className="bi bi-x-lg"></i>
          )}
        </Button>
        <Nav className="flex-column">
          <Nav.Link
            className={`nav-link ${activeTab === "Home" ? "active" : ""}`}
            onClick={() => handleTabClick("Home")}
            style={{
              margin: "10px",
              color: `${activeTab === "Home" ? "#ffcc00" : "#fff"}`,
            }}
          >
            <i className="bi bi-house"></i> {!isCollapsed && "Home"}
          </Nav.Link>
          <Nav.Link
            className={`nav-link ${activeTab === "Profile" ? "active" : ""}`}
            onClick={() => handleTabClick("Profile")}
            style={{
              margin: "10px",
              color: `${activeTab === "Profile" ? "#ffcc00" : "#fff"}`,
            }}
          >
            <i className="bi bi-person"></i> {!isCollapsed && "Profile"}
          </Nav.Link>
          <Nav.Link
            className={`nav-link ${activeTab === "Upload" ? "active" : ""}`}
            onClick={() => handleTabClick("Upload")}
            style={{
              margin: "10px",
              color: `${activeTab === "Upload" ? "#ffcc00" : "#fff"}`,
            }}
          >
            <i className="bi bi-cloud-upload"></i> {!isCollapsed && "Upload"}
          </Nav.Link>
          <Nav.Link
            className={`nav-link ${activeTab === "Settings" ? "active" : ""}`}
            onClick={() => handleTabClick("Settings")}
            style={{
              margin: "10px",
              color: `${activeTab === "Settings" ? "#ffcc00" : "#fff"}`,
            }}
          >
            <i className="bi bi-gear"></i> {!isCollapsed && "Settings"}
          </Nav.Link>
        </Nav>
        <div className="sidebar-logout">
          <Button onClick={handleLogout}>
            <i className="bi bi-box-arrow-right"></i> {!isCollapsed && "Logout"}
          </Button>
        </div>
      </div>

      <div className="main-content">
        <div className="user-info">
          <h3>Welcome, {userName}</h3>
        </div>
        <div className="content">
          {activeTab === "Home" && <div>Home Content</div>}
          {activeTab === "Profile" && <div>Profile Content</div>}
          {activeTab === "Upload" && (
            <div>
              <ImageUpload />
              <ImageGallery />
            </div>
          )}
          {activeTab === "Settings" && <div>Settings Content</div>}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
