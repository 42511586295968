import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Button,
  ListGroup,
} from "react-bootstrap";
import "../main.css";

import workingImage1 from "../assets/working-1.jpg";
import workingImage2 from "../assets/working-2.jpg";
import workingImage3 from "../assets/working-3.jpg";
import workingImage4 from "../assets/working-4.jpg";

const tabData = [
  {
    id: 1,
    icon: "bi-bar-chart",
    title: "Emergency Towing",
    content: {
      heading: "Reliable Assistance, Anytime, Anywhere",
      description:
        "When you're stranded due to a breakdown or accident, our 24/7 emergency towing service is here to help.",
      bulletPoints: [
        "Rapid response times to minimize wait.",
        "Safe and secure towing of all vehicle types.",
        "Available day or night, in any weather condition.",
        "Experienced drivers ensuring professional service.",
      ],
      image: workingImage1,
    },
  },
  {
    id: 2,
    icon: "bi-rocket",
    title: "Roadside Assistance",
    content: {
      heading: "Roadside Assistance",
      description:
        "Our comprehensive roadside assistance gets you back on the road quickly and safely, no matter the issue.",
      bulletPoints: [
        "Battery jump-starts to revive your vehicle.",
        "Tire changes to keep you moving.",
        "Fuel delivery when you're running on empty.",
        "Lockout services to get you back in your car.",
      ],
      image: workingImage2,
    },
  },
  {
    id: 3,
    icon: "bi-briefcase",
    title: "Long-Distance Vehicle Transport",
    content: {
      heading: "Long-Distance Vehicle Transport",
      description:
        "We provide reliable long-distance transport for vehicles, ensuring safe delivery wherever you need them.",
      bulletPoints: [
        "Door-to-door service across town or state.",
        "Secure handling of all vehicle types.",
        "GPS tracking for real-time updates.",
        "Professional coordination for seamless logistics.",
      ],
      image: workingImage3,
    },
  },
  {
    id: 4,
    icon: "bi-globe",
    title: "Specialized Towing",
    content: {
      heading: "Specialized Towing",
      description:
        "Our specialized towing services are designed to handle unique vehicles and situations with care and precision.",
      bulletPoints: [
        "Flatbed towing for low-clearance vehicles",
        "Motorcycle towing with specialized equipment",
        "Heavy-duty towing for trucks and large vehicles",
        "Secure transport for luxury and classic cars",
      ],
      image: workingImage4,
    },
  },
];

const AboutUs: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string>("Emergency Towing");

  return (
    <section id="about" className="about-section">
      <Container>
        {/* Header */}
        <Row className="text-center mb-5">
          <Col>
            <small className="text-warning text-uppercase">About</small>
            <h2 className="font-weight-bold">Who We Are</h2>
          </Col>
        </Row>
        {/* Description */}
        <Container>
          <Row className="mb-5">
            <Col md={6}>
              <p className="lead text-muted">
                At Marquel Wrecking, we offer a comprehensive range of towing
                services to meet your specific needs. Whether you're stranded on
                the roadside or need a vehicle moved to a new location, our
                expert team is here to help. We handle every situation with care
                and efficiency, ensuring your vehicle is transported safely and
                securely. From emergency tows to scheduled transports, we’ve got
                you covered.
              </p>
            </Col>
            <Col md={6}>
              <p className="lead text-muted">
                Our services extend beyond just towing; we also offer roadside
                assistance to get you back on the road quickly. From
                jump-starting a dead battery to changing a flat tire or
                providing fuel delivery, our skilled technicians are ready to
                assist you in any roadside emergency. We understand that vehicle
                troubles can be stressful, so we aim to provide prompt and
                efficient service to minimize any disruption to your day.
              </p>
              <Button href="#read-more" variant="warning" className="mt-3">
                Read More
              </Button>
            </Col>
          </Row>
        </Container>

        {/* Tabs Section */}
        <Tabs
          activeKey={activeKey}
          onSelect={(k) => setActiveKey(k ?? "Emergency Towing")}
          className="mb-4 custom-tabs"
          justify
        >
          {tabData.map((tab) => (
            <Tab
              eventKey={tab.title}
              title={
                <div
                  style={{
                    backgroundColor:
                      activeKey === tab.title ? "#fba314" : "#ffffff",
                    color: activeKey === tab.title ? "#ffffff" : "#000000",
                    padding: "10px",
                    borderRadius: "0.25rem",
                    border: "1px solid #dee2e6",
                    transition: "background-color 0.3s ease, color 0.3s ease",
                  }}
                >
                  <i className={`bi ${tab.icon}`} /> {tab.title}
                </div>
              }
              key={tab.id}
              className="custom-tab"
            >
              <Row className="mt-4">
                <Col md={7}>
                  <h3>{tab.content.heading}</h3>
                  <p>{tab.content.description}</p>
                  <ListGroup variant="flush">
                    {tab.content.bulletPoints.map((point, index) => (
                      <ListGroup.Item key={index}>
                        <i className="bi bi-check-all text-warning"></i> {point}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Col>
                <Col md={5}>
                  <img
                    src={tab.content.image}
                    alt={tab.title}
                    className="img-fluid rounded"
                  />
                </Col>
              </Row>
            </Tab>
          ))}
        </Tabs>
      </Container>
    </section>
  );
};

export default AboutUs;
