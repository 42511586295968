import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import "../main.css";

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Simulate form submission
    if (formData.name && formData.email && formData.message) {
      // Here you would typically send formData to your backend
      setSuccess(true);
      setError(false);
      setFormData({ name: "", email: "", message: "" });
    } else {
      setError(true);
      setSuccess(false);
    }
  };

  return (
    <Container id="contact" className="my-5" style={{ maxWidth: "80%" }}>
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <h2 className="text-center mb-4">Contact Us</h2>
          {success && (
            <Alert variant="success">
              Your message has been sent successfully!
            </Alert>
          )}
          {error && <Alert variant="danger">Please fill out all fields.</Alert>}
          <Form onSubmit={handleSubmit} className="contact-form">
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="form-control-custom"
              />
            </Form.Group>
            <Form.Group controlId="formEmail" className="my-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-control-custom"
              />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Your message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="form-control-custom"
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3 w-100">
              Send
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
