import React, { useState, useEffect } from "react";
import { Container, Card, Button, Form, Row, Col } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css"; // Import Bootstrap Icons CSS

interface ImageData {
  id: string;
  image: string | null;
  title?: string;
}

const ImageGallery: React.FC = () => {
  const [galleryData, setGalleryData] = useState<ImageData[]>([]);
  const [editImageId, setEditImageId] = useState<string | null>(null);
  const [newTitle, setNewTitle] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch images on component mount
  useEffect(() => {
    fetch(`${apiUrl}/gallery.php`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" && Array.isArray(data.data)) {
          setGalleryData(data.data);
        } else {
          console.error("Unexpected response format:", data);
        }
      })
      .catch((error) => console.error("Error fetching gallery data:", error));
  }, [apiUrl]);

  // Handle deletion of an image
  const handleDelete = (id: string) => {
    fetch(`${apiUrl}/delete_image.php?id=${id}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setGalleryData(galleryData.filter((image) => image.id !== id));
        }
      })
      .catch((error) => console.error("Error deleting image:", error));
  };

  // Handle update of image title
  const handleUpdate = (id: string) => {
    fetch(`${apiUrl}/update_image.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, title: newTitle }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setGalleryData(
            galleryData.map((image) =>
              image.id === id ? { ...image, title: newTitle } : image
            )
          );
          setEditImageId(null);
        }
      })
      .catch((error) => console.error("Error updating image:", error));
  };

  // Move item up
  const moveUp = (index: number) => {
    if (index === 0) return; // Cannot move up the first item

    const updatedGalleryData = [...galleryData];
    const [movedItem] = updatedGalleryData.splice(index, 1);
    updatedGalleryData.splice(index - 1, 0, movedItem);

    setGalleryData(updatedGalleryData);
  };

  // Move item down
  const moveDown = (index: number) => {
    if (index === galleryData.length - 1) return; // Cannot move down the last item

    const updatedGalleryData = [...galleryData];
    const [movedItem] = updatedGalleryData.splice(index, 1);
    updatedGalleryData.splice(index + 1, 0, movedItem);

    setGalleryData(updatedGalleryData);
  };

  return (
    <Container className="mt-4">
      <h2>Image </h2>
      <Row>
        {galleryData.length > 0 ? (
          galleryData.map((imageData, index) => (
            <Col md={4} lg={3} key={imageData.id} className="mb-4">
              <Card>
                {imageData.image ? (
                  <Card.Img
                    variant="top"
                    src={`${imageData.image}`}
                    alt={`Gallery Item ${index + 1}`}
                  />
                ) : (
                  <Card.Body>
                    <p>Image not available</p>
                  </Card.Body>
                )}
                <Card.Body>
                  <Card.Title>
                    {editImageId === imageData.id ? (
                      <Form.Control
                        type="text"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                      />
                    ) : (
                      imageData.title || "No Title"
                    )}
                  </Card.Title>
                  <div className="d-flex justify-content-between">
                    {editImageId === imageData.id ? (
                      <Button
                        variant="success"
                        onClick={() => handleUpdate(imageData.id)}
                      >
                        <i className="bi bi-save"></i> Save
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="danger"
                          onClick={() => handleDelete(imageData.id)}
                        >
                          <i className="bi bi-trash"></i> Delete
                        </Button>
                        {/* <Button
                          variant="light"
                          onClick={() => moveUp(index)}
                          disabled={index === 0}
                        >
                          <i className="bi bi-arrow-up"></i>
                        </Button>
                        <Button
                          variant="light"
                          onClick={() => moveDown(index)}
                          disabled={index === galleryData.length - 1}
                        >
                          <i className="bi bi-arrow-down"></i>
                        </Button> */}
                      </>
                    )}
                    {/* <Button
                      variant="light"
                      onClick={() =>
                        setEditImageId(
                          editImageId === imageData.id ? null : imageData.id
                        )
                      }
                    >
                      {editImageId === imageData.id ? (
                        <i className="bi bi-x"></i>
                      ) : (
                        <i className="bi bi-pencil"></i>
                      )}
                    </Button> */}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p>No images available.</p>
        )}
      </Row>
    </Container>
  );
};

export default ImageGallery;
