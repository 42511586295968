import React, { useState } from "react";
import axios from "axios";

const ImageUpload: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
    setFiles(selectedFiles);

    const filePreviews = selectedFiles.map((file) => URL.createObjectURL(file));
    setPreviews(filePreviews);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (files.length === 0) {
      setUploadStatus("No files selected");
      return;
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("images[]", file);
    });

    try {
      const response = await axios.post(`${apiUrl}/upload.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === "success") {
        setUploadStatus("Files successfully uploaded.");
        // Clear the files and previews
        setFiles([]);
        setPreviews([]);
      } else {
        setUploadStatus(`Upload failed: ${response.data.message}`);
      }
    } catch (error) {
      setUploadStatus("Error uploading the images.");
      console.error("Error uploading the images", error);
    }
  };

  return (
    <div className="container mt-5">
      <h3>Upload Images</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="file"
            className="form-control mb-2"
            onChange={handleFileChange}
            multiple
          />
          <button type="submit" className="btn btn-primary">
            Upload
          </button>
        </div>
        <div className="mt-3">
          {previews.map((preview, index) => (
            <img
              key={index}
              src={preview}
              alt={`Preview ${index + 1}`}
              width="200"
              className="me-2 mb-2"
            />
          ))}
        </div>
      </form>
      {uploadStatus && (
        <div className="mt-3">
          <p>{uploadStatus}</p>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
