import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../main.css";

const Footer: React.FC = () => {
  return (
    <footer className="bg-dark text-white py-4">
      <Container>
        {/* Footer Info */}
        <Row className="text-center">
          <Col>
            <h5 className="font-weight-bold">Marquel Wrecking</h5>
            <p className="mb-1">Fast, Reliable, and Ready to Roll</p>
            <hr
              className="my-3"
              style={{ borderColor: "rgba(255,255,255,0.2)" }}
            />
            {/* Social Media Icons */}
            <Row className="text-center mb-3">
              <Col>
                <div className="social-icons">
                  <a
                    href="https://www.facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a
                    href="https://www.twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a
                    href="https://www.instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                  >
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </Col>
            </Row>
            <p className="mb-0">
              &copy; {new Date().getFullYear()} Marquel Wrecking All Rights
              Reserved
            </p>
            <small>Designed by Apps Design Jamaica</small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
