import React, { useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css"; // Import Bootstrap icons
import "../main.css";

const cardData = [
  {
    id: 1,
    icon: "bi-bar-chart-line",
    title: "24/7 Emergency Towing",
    description:
      "Our around-the-clock emergency towing service is always ready to assist you. Whether it’s day or night, we ensure a fast response to get you and your vehicle to safety.",
  },
  {
    id: 2,
    icon: "bi-rocket",
    title: "Comprehensive Roadside Assistance",
    description:
      "From jump-starts to tire changes, our roadside assistance services cover all the essentials to get you back on the road quickly. We’re here to help when you need it most.",
  },
  {
    id: 3,
    icon: "bi-briefcase",
    title: "Scheduled Vehicle Transport",
    description:
      "Need to move a vehicle across town or across the state? Our scheduled transport service provides secure, reliable transportation, ensuring your vehicle arrives safely at its destination.",
  },
  {
    id: 4,
    icon: "bi-globe",
    title: "Specialized Towing Services",
    description:
      "We handle more than just standard vehicles. Our specialized towing services cater to motorcycles, heavy-duty trucks, and luxury cars, with equipment and expertise tailored to each unique situation.",
  },
  {
    id: 5,
    icon: "bi-tools",
    title: "Accident Recovery",
    description:
      "In the aftermath of an accident, our accident recovery service helps clear the scene and transport your vehicle safely. We work quickly and efficiently to handle damaged vehicles and ensure a smooth recovery process.",
  },
  {
    id: 6,
    icon: "bi-lightbulb",
    title: "Vehicle Storage Solutions",
    description:
      "Need a safe place to keep your vehicle? Our secure storage solutions offer peace of mind, with facilities designed to protect your vehicle from the elements and unauthorized access.",
  },
  {
    id: 7,
    icon: "bi-gear",
    title: "Tire Change Service",
    description:
      "We provide quick and efficient tire change services, ensuring you’re back on the road without any hassle.",
  },
  {
    id: 8,
    icon: "bi-fuel-pump",
    title: "Fuel Delivery",
    description:
      "If you run out of fuel, our fuel delivery service will bring you enough to get back on your way.",
  },
  {
    id: 9,
    icon: "bi-battery-charging",
    title: "Battery Replacement",
    description:
      "We offer reliable battery replacement services to ensure your car starts smoothly and reliably.",
  },
];

const Services: React.FC = () => {
  const [visible, setVisible] = useState(6); // Show only 6 services initially

  const showMore = () => {
    setVisible(cardData.length); // Show all services when "See More" is clicked
  };

  return (
    <section id="services" className="services-section">
      <div className="services-header">
        <small className="text-warning text-uppercase">Services</small>
        <h2>What We Offer</h2>
      </div>
      <Container>
        <Row className="g-4">
          {cardData.slice(0, visible).map((card) => (
            <Col key={card.id} xs={12} sm={6} md={4}>
              <Card className="service-card">
                <Card.Body className="text-center">
                  <i
                    className={`card-icon bi ${card.icon}`}
                    style={{ fontSize: "3rem", marginBottom: "1rem" }}
                  ></i>
                  <Card.Title className="card-title">{card.title}</Card.Title>
                  <Card.Text className="card-description">
                    {card.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {visible < cardData.length && (
          <div className="text-center mt-4">
            <Button variant="warning" className="mt-3" onClick={showMore}>
              See More
            </Button>
          </div>
        )}
      </Container>
    </section>
  );
};

export default Services;
