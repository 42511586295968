import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Image,
  Carousel,
  Container,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../main.css"; // Ensure this file includes your custom styles
import Header from "../../components/Header";
import Footer from "../../components/Footer";

interface ImageData {
  id: string;
  image: string | null;
  title?: string;
}

const FullGallery: React.FC = () => {
  const [galleryData, setGalleryData] = useState<ImageData[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch images on component mount
  useEffect(() => {
    fetch(`${apiUrl}/gallery.php`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" && Array.isArray(data.data)) {
          setGalleryData(data.data);
        } else {
          console.error("Unexpected response format:", data);
        }
      })
      .catch((error) => console.error("Error fetching gallery data:", error));
  }, []);

  const handleShowModal = (index: number) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSelect = (selectedIndex: number) => {
    setCurrentImageIndex(selectedIndex);
  };

  return (
    <div>
      <Header />
      {/* Top Section About the Company */}
      <section className="company-info py-5 text-center">
        <Container>
          <h1>Welcome to Marquel Wrecking</h1>
          <p>
            At Marquel Wrecking, we take pride in offering exceptional services
            and showcasing the finest collection of work. Our gallery highlights
            our dedication to quality and innovation. Browse through our images
            to get a glimpse of our expertise and the value we bring to our
            clients.
          </p>
        </Container>
      </section>

      {/* Gallery Grid */}
      <section className="gallery-section py-5">
        <Container>
          <Row className="gallery-grid">
            {galleryData.map((imageData, index) => (
              <Col md={4} key={imageData.id} className="mb-3">
                <Image
                  src={`${imageData.image}`}
                  alt={imageData.title || "Gallery Image"}
                  fluid
                  className="gallery-image"
                  onClick={() => handleShowModal(index)}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Modal for Image Slideshow */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Slideshow</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel
            activeIndex={currentImageIndex}
            onSelect={handleSelect}
            indicators={false} // Optional: Hide indicators if you don't need them
          >
            {galleryData.map((imageData, index) => (
              <Carousel.Item key={imageData.id}>
                <Image
                  src={`${imageData.image}`}
                  alt={imageData.title || "Gallery Image"}
                  className="d-block w-100"
                />
                <Carousel.Caption>
                  <h5>{imageData.title}</h5>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </div>
  );
};

export default FullGallery;
