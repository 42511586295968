import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LandingPage from "./pages/landing/LandingPage";

import Login from "./pages/Login/Login";

import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./pages/dashboard/Dashboard";
import FullGallery from "./pages/fullGallery/FullGallery";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path="/login" element={<Login />} />
        <Route path="/allgallery" element={<FullGallery />} />

        <Route
          path="/dashboard"
          element={
            <PrivateRoute
              element={<Dashboard userName="User" onLogout={() => {}} />}
            />
          } // Use PrivateRoute
        />
        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;
