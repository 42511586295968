import React, { useEffect, useState } from "react";
import { Modal, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../main.css";

interface ImageData {
  id: string;
  image: string | null;
}

const Gallery: React.FC = () => {
  const [galleryData, setGalleryData] = useState<ImageData[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState<string | null>(null);
  const [itemsToShow, setItemsToShow] = useState(6); // Show 6 items initially

  const navigate = useNavigate(); // Hook to navigate programmatically
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${apiUrl}/gallery.php`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" && Array.isArray(data.data)) {
          setGalleryData(data.data);
        } else {
          console.error("Unexpected response format:", data);
        }
      })
      .catch((error) => console.error("Error fetching gallery data:", error));
  }, []);

  const handleShow = (image: string) => {
    setModalImage(image);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setModalImage(null);
  };

  const handleSeeMore = () => {
    navigate("/allgallery"); // Navigate to the /allgallery route
  };

  return (
    <section id="gallery" className="gallery-section">
      <div className="gallery-header">
        <small className="text-warning text-uppercase">Gallery</small>
        <h2>Our Works</h2>
      </div>
      <Container>
        <div className="gallery-grid">
          {galleryData.slice(0, itemsToShow).map((imageData) =>
            imageData.image ? (
              <div
                key={imageData.id}
                className="gallery-card"
                onClick={() => handleShow(`${imageData.image}`)}
              >
                <img
                  src={`${imageData.image}`}
                  alt={`Gallery Item ${imageData.id}`}
                  className="gallery-image"
                />
              </div>
            ) : null
          )}
        </div>
        <div className="text-center mt-3">
          <Button variant="warning" onClick={handleSeeMore}>
            See More
          </Button>
        </div>
      </Container>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Body className="p-0">
          {modalImage && (
            <img src={modalImage} alt="Modal Image" className="modal-image" />
          )}
        </Modal.Body>
        <Button
          variant="link"
          onClick={handleClose}
          className="modal-close-btn"
        >
          <i className={`bi bi-x-lg`}></i>
        </Button>
      </Modal>
    </section>
  );
};

export default Gallery;
