import React, { useState, useEffect } from "react";
import "../main.css";
import logo from "../assets/Marquel.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState<string>("");

  // Handle scroll to add/remove 'scrolled' class and update active link
  const handleScroll = () => {
    const header = document.querySelector("header");
    if (window.scrollY > 50 && header) {
      header.classList.add("scrolled");
    } else if (header) {
      header.classList.remove("scrolled");
    }

    const sections = document.querySelectorAll("section");
    let currentLink = "";
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;
      if (
        window.scrollY >= sectionTop - 50 &&
        window.scrollY < sectionTop + sectionHeight - 50
      ) {
        currentLink = `#${section.id}`;
      }
    });
    setActiveLink(currentLink);
  };

  // Attach the scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); // Cleanup
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header id="header" className="header d-flex align-items-center fixed-top">
      <Navbar
        expand="lg"
        className={`full-width-navbar ${menuOpen ? "open" : ""}`}
      >
        <Container fluid>
          <Navbar.Brand href="/" className="d-flex align-items-center">
            <img src={logo} alt="Marquel Wrecking" className="logo-img" />
            <h2 className="ms-2">
              <strong>Marquel Wrecking</strong>
            </h2>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={toggleMenu}
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className={menuOpen ? "show" : ""}
          >
            {/* Align Nav Links to the end */}
            <Nav className="ms-auto">
              <Nav.Link href="/" className={activeLink === "/" ? "active" : ""}>
                Home
              </Nav.Link>
              <Nav.Link
                href="#about"
                className={activeLink === "#about" ? "active" : ""}
              >
                About
              </Nav.Link>
              <Nav.Link
                href="#services"
                className={activeLink === "#services" ? "active" : ""}
              >
                Services
              </Nav.Link>
              <Nav.Link
                href="#gallery"
                className={activeLink === "#gallery" ? "active" : ""}
              >
                Gallery
              </Nav.Link>
              <Nav.Link
                href="#contact"
                className={activeLink === "#contact" ? "active" : ""}
              >
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
