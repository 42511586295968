import React, { useState, useEffect } from "react";
import BootstrapCarousel from "react-bootstrap/Carousel";
import "../main.css";

// Import images directly or use require if needed
import workingImage1 from "../assets/working-1.jpg";
import workingImage2 from "../assets/working-2.jpg";
import workingImage3 from "../assets/working-3.jpg";
import { Button } from "react-bootstrap";

interface CarouselItem {
  imageUrl: string;
  title: string;
  description: string;
  link: string;
}

const carouselData: CarouselItem[] = [
  {
    imageUrl: workingImage1,
    title: "Your Vehicle Is in Good Hands",
    description:
      "When you choose Marquel Wrecking, you’re choosing a towing service known for professionalism and reliability. Our skilled drivers and state-of-the-art equipment ensure that every tow is handled with the utmost care. We pride ourselves on prompt response times, transparent communication, and a commitment to getting you back on the road as quickly as possible.",
    link: "#about",
  },
  {
    imageUrl: workingImage2,
    title: "Tailored to Your Needs",
    description:
      "At Marquel Wrecking, we provide a wide range of towing services designed to fit your specific situation. Whether it's a roadside emergency, a long-distance transport, or specialized vehicle handling, our team ensures every tow is conducted with precision and care. We manage everything from the initial call to final delivery, so you can rest easy knowing your vehicle is in safe hands.",
    link: "#about",
  },
  {
    imageUrl: workingImage3,
    title: "Join Our Team",
    description: "We are looking for talented individuals to work with us.",
    link: "#team",
  },
];

const CustomCarousel: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % carouselData.length);
    }, 3000); // Change slide every 3 seconds

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  return (
    <BootstrapCarousel className="custom-carousel" id="home">
      {carouselData.map((item, index) => (
        <BootstrapCarousel.Item key={index}>
          <img
            className="d-block w-100 carousel-image"
            src={item.imageUrl}
            alt={item.title}
          />
          <div className="carousel-caption-container">
            <div className="carousel-caption-content">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
              <Button href={item.link} variant="warning" className="mt-3">
                Learn More
              </Button>
            </div>
          </div>
        </BootstrapCarousel.Item>
      ))}
    </BootstrapCarousel>
  );
};

export default CustomCarousel;
