import React from "react";
import "../main.css"; // Ensure to use the same global CSS file

const BannerSection: React.FC = () => {
  return (
    <section className="banner-section">
      <div className="banner-content">
        <div className="banner-text">
          <h1>Welcome to Our Company</h1>
          <p>
            We are dedicated to delivering top-notch solutions for your
            business. Explore our services and find out how we can help you
            achieve your goals.
          </p>
        </div>
        <div className="banner-button">
          <a href="#services" className="btn-learn-more">
            Learn More
          </a>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
