import React from "react";
import Header from "../../components/Header";

import AboutUs from "../../components/AboutUs";
import BannerSection from "../../components/BannerSection";
import Services from "../../components/Services";
import Gallery from "../../components/Gallery";
import CustomCarousel from "../../components/Carousel";
import Footer from "../../components/Footer";
import Contact from "../../components/Contact";

type Props = {};

const LandingPage = (props: Props) => {
  return (
    <>
      <Header />
      <CustomCarousel />
      <AboutUs />
      <BannerSection />
      <Services />
      <Gallery />
      <Contact />
      <Footer />
    </>
  );
};

export default LandingPage;
